<template>
  <b-card>
    <load-profile></load-profile>
    <div v-if="data.id">
      <b-card-title title-tag="h2" class="font-weight-bold mb-1">
        {{ $t("breadcrumb.contactInformation") }}
      </b-card-title>

      <b-row class="mt-2">
        <b-col cols="auto" class="text-center">
          <h5 class="mt-1 mb-0 text-capitalize profile-name">
            {{ data.firstName }} {{ data.lastName }}
          </h5>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <p v-if="data.email">
            <feather-icon icon="MailIcon" /> {{ data.email }}
          </p>
        </b-col>
        <b-col>
          <p v-if="data.phoneNumber">
            <feather-icon icon="PhoneIcon" /> {{ data.phoneNumber }}
          </p>
        </b-col>
        <b-col>
          <p>
            <feather-icon icon="CalendarIcon" />
            {{ data.createdAt | formatDate("LL") }}
          </p>
        </b-col>
      </b-row>

      <hr class="my-2" />
      <p>{{ data.subject }}</p>
      <p>{{ data.message }}</p>
    </div>
  </b-card>
</template>
  
<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";

const ContactRepository = Repository.get("contact");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      data: {},
      loading: false,
      avatarText,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      ContactRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };

          if (this.data.subject === "general") {
            this.data.subject = "General Enquiries";
          } else if (this.data.subject === "office") {
            this.data.subject = "Office Enquiries";
          } else if (this.data.subject === "shopping") {
            this.data.subject = "Shopping Enquiries";
          }
        }
      });
    },
  },
  setup() {
    const resource = "contact";
    const route = "contact";

    return { resource, route };
  },
};
</script>
  